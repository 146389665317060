import { getDefaultText, restoreDefaultText } from './util'
import { handleFormReset, handleinputChange } from './eventHandlers'
import Selector from './selector'

const customProperty = 'bsCustomFileinput'
const Event = {
  FORMRESET   : 'reset',
  inputCHANGE : 'change',
}

const bsCustomFileinput = {
  init(inputSelector = Selector.CUSTOMFILE, formSelector = Selector.FORM) {
    const customFileinputList = [].slice.call(document.querySelectorAll(inputSelector))
    const formList = [].slice.call(document.querySelectorAll(formSelector))

    for (let i = 0, len = customFileinputList.length; i < len; i++) {
      const input = customFileinputList[i]

      Object.defineProperty(input, customProperty, {
        value: {
          defaultText: getDefaultText(input),
        },
        writable: true,
      })

      handleinputChange.call(input)
      input.addEventListener(Event.inputCHANGE, handleinputChange)
    }

    for (let i = 0, len = formList.length; i < len; i++) {
      formList[i].addEventListener(Event.FORMRESET, handleFormReset)
      Object.defineProperty(formList[i], customProperty, {
        value: true,
        writable: true,
      })
    }
  },

  destroy() {
    const formList = [].slice.call(document.querySelectorAll(Selector.FORM))
      .filter((form) => !!form.bsCustomFileinput)
    const customFileinputList = [].slice.call(document.querySelectorAll(Selector.input))
      .filter((input) => !!input.bsCustomFileinput)

    for (let i = 0, len = customFileinputList.length; i < len; i++) {
      const input = customFileinputList[i]

      restoreDefaultText(input)
      input[customProperty] = undefined

      input.removeEventListener(Event.inputCHANGE, handleinputChange)
    }

    for (let i = 0, len = formList.length; i < len; i++) {
      formList[i].removeEventListener(Event.FORMRESET, handleFormReset)
      formList[i][customProperty] = undefined
    }
  },
}

export default bsCustomFileinput
